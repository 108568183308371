import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { Spinner } from 'reactstrap';
// import Navigation from "./components/Navigation/Navigation";
// import Home from "./components/Home/Home";
// import Forms from './components/Forms';
// import Library from './components/Library';
// import Schedule from './components/Schedule';
// import Notice from './components/Notice';
// import Profile from './components/Profile';
import './App.css';

const loading = () => <div className="animated fadeIn pt-3 text-center">
  <Spinner style={{ width: '3rem', height: '3rem' }} color="danger" />
</div>;

// Containers
const LoginLayout = React.lazy(() => import('./components/Layout/LoginLayout'));
const MainLayout = React.lazy(() => import('./components/Layout/MainLayout'));

class App extends Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" component={LoginLayout} />
            <PrivateRoute path='/' name="Home" component={MainLayout} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;


// function App() {
//   return (
//     <div>
//       <BrowserRouter>
//         {/* <Navigation/> */}
//         <Home />
//         <Switch>
//           <Route path="/forms" component={Forms} />
//           <Route path="/library" component={Library} />
//           <Route path="/schedule" component={Schedule} />
//           <Route path="/notice" component={Notice} />
//           <Route path="/profile" component={Profile} />
//         </Switch>
//       </BrowserRouter>
//     </div>
//   );
// }

// export default App;